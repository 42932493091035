/********** Add Your Global CSS Here **********/
html,
body {
  height: auto;
}
body {
  -webkit-overflow-scrolling: touch;
}
/* remove input number spinner */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* for mozilla */
input[type='number'] {
  -moz-appearance: textfield;
}

html h1,
html h2,
html h3,
html h4,
html h5,
html h6,
html a,
html p,
html li,
input,
textarea,
span,
div,
html,
body,
html a {
  margin-bottom: 0;
  /* font-family: 'Roboto', sans-serif; */
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
}

html ul {
  -webkit-padding-start: 0px;
  list-style: none;
  margin-bottom: 0;
}

.scrollbar-track-y,
.scrollbar-thumb-y {
  width: 5px !important;
}

.scrollbar-track-x,
.scrollbar-thumb-x {
  height: 5px !important;
}

.scrollbar-thumb {
  border-radius: 0 !important;
}

.scrollbar-track {
  background: rgba(222, 222, 222, 0.15) !important;
}

.scrollbar-thumb {
  border-radius: 0 !important;
  background: rgba(0, 0, 0, 0.5) !important;
}

.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow:after,
.ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow:after,
.ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow:after,
.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow:after,
.ant-popover-placement-topLeft > .ant-popover-content > .ant-popover-arrow:after,
.ant-popover-placement-topRight > .ant-popover-content > .ant-popover-arrow:after {
  left: 0;
  margin-left: -4px;
}

/********** Add Your Global RTL CSS Here **********/
/* FONTS */
/* montserrat-300 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: url('../../assets/fonts/montserrat-v14-latin/montserrat-v14-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Montserrat Light'), local('Montserrat-Light'),
    url('../../assets/fonts/montserrat-v14-latin/montserrat-v14-latin-300.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../../assets/fonts/montserrat-v14-latin/montserrat-v14-latin-300.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../../assets/fonts/montserrat-v14-latin/montserrat-v14-latin-300.woff') format('woff'),
    /* Modern Browsers */
      url('../../assets/fonts/montserrat-v14-latin/montserrat-v14-latin-300.ttf') format('truetype'),
    /* Safari, Android, iOS */
      url('../../assets/fonts/montserrat-v14-latin/montserrat-v14-latin-300.svg#Montserrat')
      format('svg'); /* Legacy iOS */
}
/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('../../assets/fonts/montserrat-v14-latin/montserrat-v14-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Montserrat Regular'), local('Montserrat-Regular'),
    url('../../assets/fonts/montserrat-v14-latin/montserrat-v14-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../../assets/fonts/montserrat-v14-latin/montserrat-v14-latin-regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../../assets/fonts/montserrat-v14-latin/montserrat-v14-latin-regular.woff')
      format('woff'),
    /* Modern Browsers */
      url('../../assets/fonts/montserrat-v14-latin/montserrat-v14-latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../../assets/fonts/montserrat-v14-latin/montserrat-v14-latin-regular.svg#Montserrat')
      format('svg'); /* Legacy iOS */
}
/* montserrat-500 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url('../../assets/fonts/montserrat-v14-latin/montserrat-v14-latin-500.eot'); /* IE9 Compat Modes */
  src: local('Montserrat Medium'), local('Montserrat-Medium'),
    url('../../assets/fonts/montserrat-v14-latin/montserrat-v14-latin-500.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../../assets/fonts/montserrat-v14-latin/montserrat-v14-latin-500.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../../assets/fonts/montserrat-v14-latin/montserrat-v14-latin-500.woff') format('woff'),
    /* Modern Browsers */
      url('../../assets/fonts/montserrat-v14-latin/montserrat-v14-latin-500.ttf') format('truetype'),
    /* Safari, Android, iOS */
      url('../../assets/fonts/montserrat-v14-latin/montserrat-v14-latin-500.svg#Montserrat')
      format('svg'); /* Legacy iOS */
}
/* montserrat-600 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: url('../../assets/fonts/montserrat-v14-latin/montserrat-v14-latin-600.eot'); /* IE9 Compat Modes */
  src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
    url('../../assets/fonts/montserrat-v14-latin/montserrat-v14-latin-600.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../../assets/fonts/montserrat-v14-latin/montserrat-v14-latin-600.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../../assets/fonts/montserrat-v14-latin/montserrat-v14-latin-600.woff') format('woff'),
    /* Modern Browsers */
      url('../../assets/fonts/montserrat-v14-latin/montserrat-v14-latin-600.ttf') format('truetype'),
    /* Safari, Android, iOS */
      url('../../assets/fonts/montserrat-v14-latin/montserrat-v14-latin-600.svg#Montserrat')
      format('svg'); /* Legacy iOS */
}
/* montserrat-700 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('../../assets/fonts/montserrat-v14-latin/montserrat-v14-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Montserrat Bold'), local('Montserrat-Bold'),
    url('../../assets/fonts/montserrat-v14-latin/montserrat-v14-latin-700.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../../assets/fonts/montserrat-v14-latin/montserrat-v14-latin-700.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../../assets/fonts/montserrat-v14-latin/montserrat-v14-latin-700.woff') format('woff'),
    /* Modern Browsers */
      url('../../assets/fonts/montserrat-v14-latin/montserrat-v14-latin-700.ttf') format('truetype'),
    /* Safari, Android, iOS */
      url('../../assets/fonts/montserrat-v14-latin/montserrat-v14-latin-700.svg#Montserrat')
      format('svg'); /* Legacy iOS */
}
/* montserrat-800 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  src: url('../../assets/fonts/montserrat-v14-latin/montserrat-v14-latin-800.eot'); /* IE9 Compat Modes */
  src: local('Montserrat ExtraBold'), local('Montserrat-ExtraBold'),
    url('../../assets/fonts/montserrat-v14-latin/montserrat-v14-latin-800.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../../assets/fonts/montserrat-v14-latin/montserrat-v14-latin-800.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../../assets/fonts/montserrat-v14-latin/montserrat-v14-latin-800.woff') format('woff'),
    /* Modern Browsers */
      url('../../assets/fonts/montserrat-v14-latin/montserrat-v14-latin-800.ttf') format('truetype'),
    /* Safari, Android, iOS */
      url('../../assets/fonts/montserrat-v14-latin/montserrat-v14-latin-800.svg#Montserrat')
      format('svg'); /* Legacy iOS */
}
/* montserrat-900 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  src: url('../../assets/fonts/montserrat-v14-latin/montserrat-v14-latin-900.eot'); /* IE9 Compat Modes */
  src: local('Montserrat Black'), local('Montserrat-Black'),
    url('../../assets/fonts/montserrat-v14-latin/montserrat-v14-latin-900.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../../assets/fonts/montserrat-v14-latin/montserrat-v14-latin-900.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../../assets/fonts/montserrat-v14-latin/montserrat-v14-latin-900.woff') format('woff'),
    /* Modern Browsers */
      url('../../assets/fonts/montserrat-v14-latin/montserrat-v14-latin-900.ttf') format('truetype'),
    /* Safari, Android, iOS */
      url('../../assets/fonts/montserrat-v14-latin/montserrat-v14-latin-900.svg#Montserrat')
      format('svg'); /* Legacy iOS */
}
/* Popover */

html[dir='rtl'] .ant-popover {
  text-align: right;
}

/* Ecommerce Card */

html[dir='rtl'] .isoCardInfoForm .ant-input {
  text-align: right;
}

/* Modal */

html[dir='rtl'] .has-success.has-feedback:after,
html[dir='rtl'] .has-warning.has-feedback:after,
html[dir='rtl'] .has-error.has-feedback:after,
html[dir='rtl'] .is-validating.has-feedback:after {
  left: 0;
  right: auto;
}

html[dir='rtl'] .ant-modal-close {
  right: inherit;
  left: 0;
}

html[dir='rtl'] .ant-modal-footer {
  text-align: left;
}

html[dir='rtl'] .ant-modal-footer button + button {
  margin-left: 0;
  margin-right: 8px;
}

html[dir='rtl'] .ant-confirm-body .ant-confirm-content {
  margin-right: 42px;
}

html[dir='rtl'] .ant-btn > .anticon + span,
html[dir='rtl'] .ant-btn > span + .anticon {
  margin-right: 0.5em;
}

html[dir='rtl'] .ant-btn-loading span {
  margin-left: 0;
  margin-right: 0.5em;
}

html[dir='rtl'] .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline) {
  padding-left: 25px;
  padding-right: 29px;
}

html[dir='rtl']
  .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline)
  .anticon {
  margin-right: -14px;
  margin-left: 0;
}

/* Confirm */

html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-body > .anticon {
  margin-left: 16px;
  margin-right: 0;
  float: right;
}

html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-btns {
  float: left;
}

html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-btns button + button {
  margin-right: 10px;
  margin-left: 0;
}

/* Message */

html[dir='rtl'] .ant-message .anticon {
  margin-left: 8px;
  margin-right: 0;
}

/* Pop Confirm */

html[dir='rtl'] .ant-popover-message-title {
  padding-right: 20px;
  padding-left: 0;
}

html[dir='rtl'] .ant-popover-buttons {
  text-align: left;
}

/* Notification */

html[dir='rtl'] .ant-notification-notice-closable .ant-notification-notice-message {
  padding-left: 24px;
  padding-right: 0;
}

html[dir='rtl'] .ant-notification-notice-with-icon .ant-notification-notice-message,
html[dir='rtl'] .ant-notification-notice-with-icon .ant-notification-notice-description {
  margin-right: 48px;
}

html[dir='rtl'] .ant-notification-notice-close {
  right: auto;
  left: 16px;
}

html[dir='rtl'] .ant-notification-notice-with-icon {
  left: 0;
}

/* Dropzone */

html[dir='rtl'] .dz-hidden-input {
  display: none;
}

.currency {
  font-family: 'Montserrat', sans-serif;
  font-size: 8pt;
  margin-bottom: 0px;
  font-weight: 500;
}

.centerLoader {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* view product style */
.product-image-box {
  background-color: #fff;
  padding: 15px 15px 15px 15px;
  border-radius: 0.25rem;
  margin-top: 10px;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.product-price {
  font-family: 'Montserrat', sans-serif;
  font-weight: 100;
  font-size: 18pt;
  margin-top: 0px;
}
.currency {
  font-family: 'Montserrat', sans-serif;
  font-size: 8pt;
  margin-bottom: 0px;
  font-weight: 500;
}
.product-left-data {
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  font-size: 10pt;
  margin-top: 10px;
}
.product-data-box {
  background-color: #fff;
  padding: 15px 15px 15px 15px;
  border-radius: 0 0.25rem 0 0.25rem;
  margin-top: 10px;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.product-details-title {
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  font-size: 11pt !important;
}
.product-details-text {
  font-family: 'Montserrat', sans-serif;
  /* font-weight: 100; */
  font-size: 11pt;
}
.xs-btn {
  font-size: 8pt;
  padding: 5px;
  margin: 20px 10px 10px 10px;
  background-color: #fff;
  border: none;
  color: #2e4369;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
}
.xs-btn:hover {
  background-color: #fff;
  color: #2e4369;
  border: none;
  transition: box-shadow 0.15s ease-in-out;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.35);
}
.blue-color {
  color: #2e4369;
}

.edit-button {
  background-color: #fff;
  border: none;
  color: #2e4369;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  padding: 5px;
  border-radius: 0.25rem;
  font-size: 8pt;
  cursor: pointer;
  margin-top: 0px;
  margin-left: 10px;
  transition: box-shadow 0.15s ease-in-out;
}
.edit-button:hover {
  background-color: #fff;
  color: #2e4369;
  border: none;
  transition: box-shadow 0.15s ease-in-out;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.35);
}

/* .nav-link {
  background-color: #fff;
  padding: 0px;
  margin: 0px;
} */

.image-box {
  border: none;
  padding-left: 0px;
}
.uploadimage:hover {
  cursor: pointer;
}

.Toastify-msg {
  font-size: 0.875rem;
  font-weight: 500;
  color: #001433;
  /* font-family: "Poppins", sans-serif; */
  font-family: 'Montserrat', sans-serif !important;
  margin-bottom: 0px;
  line-height: 1.6;
  cursor: pointer;
  direction: ltr;
}

.toast-success {
  padding: 12px;
  background-color: #fff;
  border: 1px solid #ebedf0;
  border-top-color: rgb(235, 237, 240);
  border-top-style: solid;
  border-top-width: 1px;
  border-top: 2px solid #2c9266;
  box-shadow: 0 -1px 6px 0 rgba(0, 0, 0, 0.1);
}

.gog {
  border-top: '2px solid #2c9266';
  color: '#000';
  min-height: '60px';
  border-radius: '8px';
  box-shadow: '2px 2px 20px 2px rgba(0,0,0,0.3)';
}

.product-image-box svg {
  width: 100%;
}

.barcode-image-box svg {
  width: 100%;
}
.nomargin-tabs .ant-tabs-bar {
  margin-bottom: 0px !important;
}

.xs-btn {
  font-size: 8pt;
  padding: 5px;
  margin: 20px 10px 10px 10px;
  background-color: #fff;
  border: none;
  color: #2e4369;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
}
.xs-btn:hover {
  background-color: #fff;
  color: #2e4369;
  border: none;
  transition: box-shadow 0.15s ease-in-out;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.35);
}

/* new */
.InovuaReactDataGrid__cell__content {
  font-family: 'Montserrat', sans-serif !important;
}
.InovuaReactDataGrid__column-header__content {
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 500 !important;
  font-size: larger;
}

.fw-500 {
  font-weight: 500;
  font-family: 'Montserrat', sans-serif !important;
}

/* .MuiPaper-root .MUIDataTable-paper-2 {
  font-weight: 500;
  font-family: 'Montserrat', sans-serif !important;
} */

.MUIDataTableToolbar-left-15 .MUIDataTableToolbar-titleRoot-19 h6 {
  font-weight: 500;
  font-family: 'Montserrat', sans-serif !important;
}

.MUIDataTableHeadCell-data-54 {
  font-weight: 500;
  font-family: 'Montserrat', sans-serif !important;
}
.MUIDataTableHeadCell-data-156 {
  font-weight: 500;
  font-family: 'Montserrat', sans-serif !important;
}
.MuiTypography-root .MUIDataTableToolbar-titleText-122 .MuiTypography-h6 {
  font-weight: 500;
  font-family: 'Montserrat', sans-serif !important;
}
.MUIDataTableToolbar-left-102 {
  font-weight: 500;
  font-family: 'Montserrat', sans-serif !important;
}
.MuiTypography-root .MUIDataTableToolbar-titleText-107 .MuiTypography-h6 {
  font-weight: 500;
  font-family: 'Montserrat', sans-serif !important;
}
/* .MUIDataTableHeadCell-toolButton-59 {
  font-weight: 500;
  font-family: 'Montserrat', sans-serif !important;
} */

.MUIDataTableBodyCell-root-70 .MUIDataTableBodyCell-stackedCommon-75 {
  font-family: 'Montserrat', sans-serif !important;
}

svg .anticon-spin {
  margin-top: -50px !important;
}

/* .ant-tag .ant-tag-has-color i svg {
  color: 'red' !important;
}
.anticon {
  color: 'blue' !important;
} */
/* .anticon svg {
  color: 'red' !important;
} */

#sync-icon svg {
  margin-bottom: 5px !important;
}

.f-w-500 {
  font-weight: 500;
}

/* .errorDetails .ant-collapse-item .ant-collapse-item-active .ant-collapse-content .ant-collapse-content-active {
  color: red !important;
} */

.errorDetails .ant-collapse-content-box {
  background-color: #fff2f0;
}
.ant-time-picker-panel-combobox {
  margin-left: 12px;
}

.ant-time-picker-panel-narrow .ant-time-picker-panel-input-wrap {
  max-width: 112px !important;
}
form label {
  font-weight: 500 !important;
  font-size: 14;
  color: rgba(0, 0, 0, 0.65);
}

.setting-label {
  font-weight: 500;
  font-size: 20px;
  line-height: 50px;
  padding: 2%;
  /* font-family: 'Montserrat', sans-serif; */
  color: rgba(0, 0, 0, 0.65);
}

.ant-form-item-label label {
  margin: 0;
}

.content-wrapper {
  border-radius: 10px;
  box-shadow: 0 7px 12px 0 rgba(22, 37, 63, 0.09);
  background-color: #fff;
  padding: 24px;
  min-height: 80vh;
}

.content-title {
  font-weight: 400;
  font-size: 20px;
  line-height: 50px;
  padding-left: 10px;
  /* padding: 2vh; */
  /* font-family: 'Montserrat', sans-serif; */
  color: rgba(0, 0, 0, 0.65);
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}

.montserrat {
  font-family: 'Montserrat', sans-serif !important;
}
